import React from 'react'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import { Helmet } from 'react-helmet';

const CookiePrompt = () => {
   const status = getCookieConsentValue();

   return (
      <React.Fragment>
         <CookieConsent
            enableDeclineButton
            location='center'
            buttonText="Accept"
            flipButtons={true}
            declineButtonText="Decline"
            cookieName="gatsby-gdpr-google-tagmanager"
            declineCookieValue={false}
            buttonClasses="standard-button primary"
            declineButtonStyle={{ backgroundColor: '#FFF', color: '#000', marginLeft: 0}}
            style={{zIndex: 224748364652}}
            expires={150}
            onAccept={() => window.location.reload()}
            overlay={true}
            containerClasses="cookie-gdpr-container"
         >
            <strong>Allow the use of cookies from Appliance Sure in this browser?</strong><br/>
            We use cookies and similar technologies to provide a better experience for you. Some cookies that we use are necessary for the use of our services, whereas others help with performance and analytics, helping us improve our service. Find out more about our use of cookies here. <a href="/cookies-policy">View cookies policy</a>
         </CookieConsent>
      </React.Fragment>
   )
}

export default CookiePrompt