const appliance_content = {
   "Washing machine": {
      average_repair_cost: "£137 - £159",
      average_cost_statement: "According to Local Heroes, the average cost to repair a washing machine between 2017-2022 was £137 - £159",
      logos: ["beko", "lg", "bosch", "samsung"]
   },
   "Dishwasher": {
      average_repair_cost: "£105",
      average_cost_statement: "According to Checkatrade, the average cost to repair a dishwasher is £105",
      logos: ["indesit", "zanussi", "lg", "hotpoint"]
   },
   "Television": {
      average_repair_cost: "£199",
      average_cost_statement: "According to Checkatrade, the average cost to repair a dishwasher is £199",
      logos: ["samsung", "sony", "panasonic", "philips"]
   },
   "Fridge": {
      average_repair_cost: "£85 - £150",
      average_cost_statement: "According to Checkatrade, the average cost to repair a fridge can range between £85 - 150",
      logos: ["smeg", "beko", "hotpoint", "hisense"]
   },
   "Storage heater": {
      average_repair_cost: "£70 - £150",
      average_cost_statement: "According to Checkatrade, the average cost to replace a faulty storage heater can range between £70 - 150",
      logos: ["dimplex", "apollo", "ultraheat", "milano"]
   },
   "Oven": {
      average_repair_cost: "£100",
      average_cost_statement: "According to Checkatrade, the average cost to repair an oven is £100",
      logos: ["zanussi", "aeg", "candy", "bosch"]
   },
   "Vacuum cleaner": {
      average_repair_cost: "£45",
      average_cost_statement: "According to AUG Hygiene, the average cost to repair a vacuum cleaner is £45",
      logos: ["dyson", "hoover", "shark", "henry"]
   }
}

export default appliance_content