import React, { useEffect, useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Navigation as NavigateSwiper } from "swiper";

// Components
import Navigation from "../components/global/navigation";
import appliance_content from "../components/local/resources/home_appliances_content";
import reviewsContent from "../components/local/resources/reviews_content";
import Footer from "../components/global/footer";
import SEO from "../components/global/seo";
import CookiePrompt from "../components/global/cookies-prompt";

// CSS
import '../styles.css'
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({top:0})
  }, [])
  // States
  const [showWhatsCovered, setShowWhatsCovered] = useState('')
  const [showAppliance, setShowAppliance] = useState('Washing machine')
  
  // Variables
  const appliances_list = ["Washing machine", "Dishwasher", "Television", "Fridge", "Storage heater", "Oven", "Vacuum cleaner"]

  const generateApplianceContent = () => {
    let jsx = []
    let applianceArray = Object.entries(appliance_content)

    for (let i = 0; i < applianceArray.length; i++) {
      const el = applianceArray[i];
      
      jsx.push(
        <React.Fragment>
          <div className={`appliance-content-entry ${showAppliance === el[0] ? 'active' : ''}`}>
            <div className="average-cost-container">
              <h4>Average cost of repair</h4>
              <h2>{el[1].average_repair_cost}</h2>
              <p>{el[1].average_cost_statement}.</p>
            </div>

            <p>We cover all brands of {el[0]}</p>
            <div className="average-cost-image-container">
              {el[1].logos.map(logo => (
                <img 
                  src={`/images/logos/${logo}-logo.png`}
                  alt={`${logo.charAt(0).toUpperCase() + logo.slice(1)} logo`}
                  title={`${logo.charAt(0).toUpperCase() + logo.slice(1)} ${el[0]} repairs`}
                />
              ))}
            </div>
            <p>... and many more</p>
          </div>
        </React.Fragment>
      )
    }

    return jsx
  }
  
  return (
    <React.Fragment>
      <CookiePrompt/>
      <SEO
        title="Appliance Sure - Cover from as little as £14.25 per month"
        slug=""
        metaDescription="Appliance Sure provides flexible and great value appliance cover to keep your world in working order. When things go wrong, we'll be there."
        image=""
        titleTemplate="%s"
      />

      {/* Content */}
      <Navigation/>

      <div className="home-hero-container">
        <div className="hero-content-container">
          <h1>When things go wrong<br/><span>We'll be there for you</span></h1>

          <p>Appliance cover from as little as £14.25 per month</p>

          <br/>

          <a className="standard-button green">Get a quote now</a>
        </div>
      </div>
      <svg viewBox="0 0 5120 795.58" className="hero-image-mask" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
        <path d="M 2560 794.376 C 1434.82 794.376 467 471.523 0 0 L 0 796.379 L 5120 796.379 L 5120 0 C 4653 471.523 3685.18 794.376 2560 794.376 Z" style={{fill: "#fafafa"}}/>
      </svg>

      <div className="standard-container grey">
        <div className="stanard-inner-container narrow-width">
          <div className="home-cover-section-container">
            <span>
              <p>Keep your appliances in working order</p>

              <h2 className="cover-section-title">Cover from as little as £14.25 per month</h2>

              <div className="home-cover-select">
                <table className="select-switch-table">
                  <tbody>
                    <tr>
                      <td
                        className={showWhatsCovered === false ? '' : 'active'}
                        onClick={() => setShowWhatsCovered(true)}
                      >What is covered?</td>
                      <td
                        className={`${showWhatsCovered === false ? 'active' : ''} false`}
                        onClick={() => setShowWhatsCovered(false)}
                      ><span>What is <u>not</u> covered?</span></td>
                    </tr>
                  </tbody>
                </table>
                <div className="select-switch-container">
                  <div className={`select-switch-content ${showWhatsCovered === true ? 'covered' : showWhatsCovered === false ? 'not-covered' : ''}`}>
                    <input
                      className="select-switch-sm-trigger invisible"
                      type="radio"
                      checked={showWhatsCovered === true}
                      onChange={() => setShowWhatsCovered(true)}
                      id="whats-covered"
                      name="show-whats-covered"
                    />

                    <label htmlFor="whats-covered" className="select-switch-label"><span><i style={{color: '#7AC200'}} class="fa-solid fa-circle-check"></i> What is covered?</span></label>
                    <div className="select-switch true">
                      <p>Engineer visit within 24 hours</p>
                      <p>Unlimited number of claims</p>
                      <p>Accidental damage</p>
                      <p>Nationwide coverage</p>
                      <p>Parts & labour</p>
                      <p>Up to £500 per claim</p>
                    </div>

                    <input
                      className="select-switch-sm-trigger invisible"
                      type="radio"
                      checked={showWhatsCovered === true}
                      onChange={() => setShowWhatsCovered(false)}
                      id="whats-not-covered"
                      name="show-whats-covered"
                    />

                    <label htmlFor="whats-not-covered" className="select-switch-label"><span><i style={{color: 'red'}} class="fa-solid fa-circle-xmark"></i> What is <u>not</u> covered?</span></label>
                    <div className="select-switch false">
                      <p>Damage caused during delivery</p>
                      <p>Route maintenance or cleaning</p>
                      <p>Pre-existing faults</p>
                      <p>Theft, loss or deliberate damage</p>
                      <p>Pre-owned or second hand items</p>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <img src="/images/man-repairing-pipes.jpg" className="cover-section-image"/>
          </div>
        </div>
      </div>

      <div className="standard-container">
        <div className="standard-inner-container narrow-width">
          <div className="appliance-switch-container">
            <div className="appliance-switch-options-container">
              <center><h3 id="appliance-switch-header-sm">Click an appliance 🔍</h3></center>
              <ul className="appliance-switch-options-list">
                {appliances_list.map(appliance => (
                  <li className={showAppliance === appliance ? 'active' : ''} onClick={() => setShowAppliance(appliance)}>
                    <span>{appliance}</span>
                  </li>
                ))}
              </ul>

              <a href="#" className="see-all-appliances">See all appliances</a>
            </div>

            <div className="appliance-switch-content">
              {generateApplianceContent()}
            </div>
          </div>

          {/*

          <br/>
          <br/>
          <br/>

          <center><h2 className="reviews-header">What do customers say?</h2></center>
          
          <div className="swiper-xs-wrapper">
          <Swiper
            modules={[Autoplay, NavigateSwiper]}
            navigation={true}
            autoplay={{ 
              delay: 1000,
              disableOnInteraction: false
            }}
            breakpoints={{
              1: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              500: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              820: {
                slidesPerView: 2,
                spaceBetween: 40
              },
              1200: {
                slidesPerView: 3.02,
                spaceBetween: 42
              }
            }}
            loop={true}
          >
            {reviewsContent.map(review => (
              <SwiperSlide>
                <div className="review-card">
                  <p>{review.content}</p>

                  <div className="review-card-lower">
                    <div className="review-card-title">
                      <img src={`/images/${review.logo}`}/>

                      <div>
                        <h4>{review.author}</h4>
                        <p>{review.source}</p>
                      </div>
                    </div>

                    <div className="review-card-star-wrapper">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          </div>

          <center><a href="#" className="standard-button primary" style={{marginTop: 30}}>See more reviews</a></center>*/}
        </div>
      </div>

            

      <Footer/>
    </React.Fragment>
  )
}

export default HomePage